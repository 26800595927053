import React from 'react'
import { Link } from 'gatsby'
import ReactWOW from 'react-wow'

// Logos partenaires
import partner2 from '../../assets/images/partner/partner2.png'
import partner8 from '../../assets/images/partner/partner8.png'
import partner9 from '../../assets/images/partner/partner9.png'
import partner12 from '../../assets/images/partner/partner12.png'
import partner14 from '../../assets/images/partner/partner14.png'
import partner15 from '../../assets/images/partner/partner15.png'
import partner19 from '../../assets/images/partner/partner19.png'
import partner21 from '../../assets/images/partner/partner21.png'
import partner23 from '../../assets/images/partner/partner23.png'

const Referents = () => {
    return (
        <div className="it-services-banner">
            <div className="container">
            <div className="row align-items-center pt-30" style={{justifyContent:'center'}}>
                    <div className="col-lg-1 col-3 col-sm-3 col-md-3">
                        <ReactWOW delay='.2s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <img src={partner2} alt="partner" />
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-1 col-3 col-sm-3 col-md-3">
                        <ReactWOW delay='.2s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <img src={partner8} alt="partner" />
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-1 col-3 col-sm-3 col-md-3">
                        <ReactWOW delay='.3s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <div className="single-partner-item">
                                    <img src={partner9} alt="partner" />
                                </div>
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-1 col-3 col-sm-3 col-md-3">
                        <ReactWOW delay='.6s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <div className="single-partner-item">
                                    <img src={partner12} alt="partner" />
                                </div>
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-1 col-3 col-sm-3 col-md-3">
                        <ReactWOW delay='.2s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <img src={partner14} alt="partner" />
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-1 col-3 col-sm-3 col-md-3">
                        <ReactWOW delay='.3s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <div className="single-partner-item">
                                    <img src={partner15} alt="partner" />
                                </div>
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-1 col-3 col-sm-3 col-md-3">
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <img src={partner19} alt="partner" />
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-1 col-3 col-sm-3 col-md-3">
                        <ReactWOW delay='.3s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <div className="single-partner-item">
                                    <img src={partner21} alt="partner" />
                                </div>
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-1 col-3 col-sm-3 col-md-3">
                        <ReactWOW delay='.5s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <div className="single-partner-item">
                                    <img src={partner23} alt="partner" />
                                </div>
                            </div>
                        </ReactWOW>
                    </div>
                </div>
                <div className="row align-items-center pb-30">
                    <div className="col-lg-12 col-md-12 pt-30 pb-70 center">
                        <h2 className="subtitle">Wispeek : Mon espace référent</h2>
                    </div>

                        <div className="col-lg-6 col-md-6">
                            <ReactWOW delay='.1s' animation='fadeInRight'>
                                <div className="btn-box section-title pt-50">
                                    <p className='pb-30'>Référent, superviseur, accédez au centre de pilotage sécurisé.</p>
                                    <Link to="https://gerer.wispeek.com" className="default-btn btn-custom">
                                        <i className="flaticon-right"></i> 
                                        Espace référent <span></span>
                                    </Link>
                                </div>
                            </ReactWOW>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <ReactWOW delay='.1s' animation='fadeInRight'>
                                <div className="btn-box section-title pt-50">
                                    <p className='pb-30'>Responsable hiérarchique, vous souhaitez déclarer un signalement.</p>
                                    <Link to="https://gerer.wispeek.com/s/ZbeRdgSeCv4hBiCeu" className="default-btn" style={{backgroundColor:'coral'}}>
                                        <i className="flaticon-right"></i> 
                                        Déclarer un signalement <span></span>
                                    </Link>
                                </div>
                            </ReactWOW>
                        </div>
                </div>
            </div>
        </div>
    )
}

export default Referents;